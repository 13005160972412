const mutations = {
  SET_ISPC(state, data) {
	  console.log('data',data)
    state.isPc = data;
	state.viewShow = true;
  },
  getFlag(state,data){
      state.flag = data
  },
  getClientWt(state,data){
    console.log(data);
    state.clientWidth = data;
    console.log(state.clientWidth); 
  },
}

export default mutations
