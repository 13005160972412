import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";  
import axios from "axios";
Vue.prototype.$axios = axios;
 
Vue.config.productionTip = false;

//pc事件适配 
import '@vant/touch-emulator';  

import mixin from '@/mixin/recalc-mixin'
Vue.mixin(mixin)

// vant组件
import "vant/lib/index.css";
import { Swipe, SwipeItem } from "vant";
import { Popup } from "vant";
import { DatetimePicker } from "vant";
import { ActionSheet } from "vant";
import { Toast } from "vant";
import { Picker } from "vant";
import { Dialog } from "vant";
import { List } from "vant";
import { Loading } from "vant";
import { Image as VanImage } from "vant";
import { Lazyload } from "vant";
import { Button } from "vant";
import { Divider } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import { Uploader } from "vant";
import { Overlay } from "vant";
import { Popover } from 'vant';
import { ImagePreview } from 'vant';


// 全局注册
Vue.use(ImagePreview);  
Vue.use(Popover);
Vue.use(Form);
Vue.use(Field);
Vue.use(Loading);
Vue.use(List);
Vue.use(Dialog);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Lazyload);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Uploader);
Vue.use(Overlay);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
