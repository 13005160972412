<template>
	<div id="app" v-cloak>
    <keep-alive> 
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
		</div>
</template>
<script>
// import RecalcMixin from '@/mixin/recalc-mixin';
export default {
	name: 'app',
	data() {
		return {
		};
	},
	// mixins: [RecalcMixin],
	mounted() { 
	},
	created() { 
	},
	methods: { 
	}
};
</script>

<style lang="scss">
@import '@/assets/css/reset.scss'; 

[v-cloak] {
  	  display:none !important;
}

body,
html {
	width: 100%;
	height: 100%;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center; 
	width: 100%;
	height: 100%;
	position: relative;
	padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
	padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
} 

#app::-webkit-scrollbar,.app::-webkit-scrollbar{
	display: none;
}

[v-cloak] {
	display: none;
}
</style>
