import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{ //预约
		path: '/',
		name: 'index',
		component: () => import('@/views/index/index.vue')
	},
	{
		//预约表单
		path: '/reservationForm',
		name: 'reservationForm',
		component: () => import('@/views/reservationForm/index.vue'),
		beforeRouteLeave(to, from) {
			console.log(to, from)
		}
	},
	{
		//预约成功
		path: '/reservationFormState',
		name: 'reservationFormState',
		component: () => import('@/views/reservationForm/state.vue')
	},
	{ //预约查询
		path: '/reservationInquire',
		name: 'reservationInquire',
		component: () => import('@/views/reservationInquire/index.vue')
	},
	{ //预约查询
		path: '/reservationInquireList',
		name: 'reservationInquireList',
		component: () => import('@/views/reservationInquire/list.vue')
	},
	{ //预约详情
		path: '/subscribeDetails',
		name: 'subscribeDetails',
		component: () => import('@/views/reservationInquire/details.vue')
	},
	{ //取消预约
		path: '/cancelReservation',
		name: 'cancelReservation',
		component: () => import('@/views/reservationInquire/cancelReservation.vue')
	},
	{ //成绩查询
		path: '/query',
		name: 'query',
		component: () => import('@/views/cjQuery/index.vue')
	},
	{ //成绩查询详情
		path: '/achievement',
		name: 'achievement',
		component: () => import('@/views/cjQuery/achievement.vue')
	},
	{ //证书查询详情
		path: '/certificate',
		name: 'certificate',
		component: () => import('@/views/cjQuery/certificate.vue')
	},
	// pc
	{ //查询证书或者成绩
		path: "/testLogIn",
		name: "testLogIn",
		component: () => import('@/views/index/TestLogIn.vue') ,
		meta: {
			keepAlive: true
		}
	},
	{
		path: "/informationFilling",
		name: "informationFilling",
		component: () => import('@/views/index/InformationFilling.vue') 
	},
	{
		path: "/successState",
		name: "successState",
		component: () => import('@/views/index/SuccessState.vue')
	},
	{ //cs
		path: '/test',
		name: 'test',
		component: () => import('@/views/test/index.vue')
	},
]

const router = new VueRouter({
	// mode: 'hash',
	mode: 'history', //去掉# 打包上传的时候使用，
	base: process.env.BASE_URL,
	routes
})
// 全局路由
router.beforeEach((to, from, next) => {
	next()
})

export default router
